<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF ACCOMPLISHMENT REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-2">
        <v-flex xs12 md1>
          <v-checkbox label="Is Yearly?" v-model="is_yearly"
                      @change="on_change_report"></v-checkbox>
        </v-flex>
        <v-flex xs12 md2>
          <v-select
            v-if="is_yearly"
            v-model="month_of"
            class="mx-3 pt-5"
            :items="month_of_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            @change="get_list_of_district"
            :rules="rules.combobox_rule"
          ></v-select>
          <v-select
            v-else
            v-model="month_of"
            class="mx-3 pt-5"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="get_list_of_district"
            :rules="rules.combobox_rule"
          ></v-select>
        </v-flex>
        <v-flex xs12 md4>
          <v-select
            v-model="district"
            class="mx-3 pt-5"
            :items="district_items"
            item-value="id"
            item-text="district_name"
            label="District/Club"
            required
            @change="get_list_of_accomplishment_items"
          ></v-select>
        </v-flex>
        <span class="headline">
        Total Expenses:{{total_expense}}
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
      </v-layout>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              District/Club
            </th>
            <th class="text-center text-uppercase">
              Date of Project
            </th>
            <th class="text-center text-uppercase">
              Type of Sector
            </th>
            <th class="text-center text-uppercase">
              Name of Project
            </th>
            <th class="text-center text-uppercase">
              Location
            </th>
            <th class="text-center text-uppercase">
              Recipients
            </th>
            <th class="text-center text-uppercase">
              Expenses
            </th>
            <th class="text-center text-uppercase">
              VIEW NARRATIVE
            </th>
            <th class="text-center text-uppercase">
              LIST OF DONORS/SPONSORS
            </th>
            <th class="text-center text-uppercase">
              LIST OF VOLUNTEERS/PARTICIPANTS
            </th>
            <th class="text-center text-uppercase">
              EXPENSES BREAKDOWN
            </th>
            <th class="text-center text-uppercase">
              VIEW PHOTOS
            </th>
            <th class="text-center text-uppercase">
              EDIT
            </th>
            <th class="text-center text-uppercase">
              DELETE
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in list_of_accomplishment"
            :key="item.id"
          >
            <td class="text-center">
              {{ item.distict.district_name }}
            </td>
            <td class="text-center">
              {{ item.date_of_project }}
            </td>
            <td class="text-center">
              {{ item.type_of_sector }}
            </td>
            <td class="text-center">
              {{ item.name_of_the_project }}
            </td>
            <td class="text-center">
              {{ item.location }}
            </td>
            <td class="text-center">
              {{ item.recipients }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.expenses) }}
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="info"
                @click="view_narrative(item)"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="view_donors(item)"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="warning"
                @click="view_participant(item)"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="error"
                @click="view_expenses(item)"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="view_photos(item)"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="error"
                @click="edit(item)"
                v-if="(district_id===item.district_id) && (month_of_items.map(function (x) {
            return x.id
          }).indexOf(month_of)===0)"
              >
                {{icons.mdiPencil}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                v-if="(district_id===item.district_id||position==='ADMIN') && (month_of_items.map(function (x) {
            return x.id
          }).indexOf(month_of)===0)"
                class="mr-2"
                color="error"
                @click="delete_accomplishmentss(item)"
              >
                {{icons.mdiDelete}}
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="donors_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">DONORS/SPONSORS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                Date
              </th>
              <th class="text-center text-uppercase">
                Name
              </th>
              <th class="text-center text-uppercase">
                Kind
              </th>
              <th class="text-center text-uppercase">
                Amount
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in list_of_donors"
              :key="item.id"
            >
              <td class="text-center">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.name }}
              </td>
              <td class="text-center">
                {{ item.kind }}
              </td>
              <td class="text-center">
                {{ item.amount }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-btn color="error" @click="donors_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="volunteers_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">VOLUNTEERS/PARTICIPANTS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                Name
              </th>
              <th class="text-center text-uppercase">
                Delete
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in list_of_volunteers"
              :key="item.id"
            >
              <td class="text-center">
                {{ item.name }}
              </td>
              <td class="text-center">
                <v-icon
                  v-if="(district_id===selected_report.district_id||position==='ADMIN')&& (month_of_items.map(function (x) {
            return x.id
          }).indexOf(month_of)===0)"
                  class="mr-2"
                  color="error"
                  @click="delete_volunteerss(item)"
                >
                  {{icons.mdiDelete}}
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-btn color="error" @click="volunteers_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="expenses_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EXPENSES BREAKDOWN</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                Particulars
              </th>
              <th class="text-center text-uppercase">
                Description
              </th>
              <th class="text-center text-uppercase">
                Amount
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in list_of_expenses"
              :key="item.id"
            >
              <td class="text-center">
                {{ item.particulars }}
              </td>
              <td class="text-center">
                {{ item.description }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-btn color="error" @click="expenses_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="narrative_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">NARRATIVE</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-textarea
          height="500px"
          class="mt-3 mb-3"
          v-model="narratives"
          dense
          label="Narratives"
          outlined
          :rules="rules.default_no_empty_rule"
        ></v-textarea>
      </v-card>
      <v-btn color="error" @click="narrative_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="edit_dialog" persistent max-width="80%">
      <v-card>
        <NewAccomplishment
          v-on:data="on_update_details"
          :key="this.key"
          :value="this.value_props"
        ></NewAccomplishment>
      </v-card>
      <v-btn color="error" @click="edit_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="photos_dialog" max-width="80%">
      <v-card>
        <v-card-text>
          <carousel :per-page=1>
            <slide>
              <img v-bind:src="photo1" alt="" width="100%" height="100%">
            </slide>
            <slide>
              <img v-bind:src="photo2" alt="" width="100%" height="100%">
            </slide>
            <slide>
              <img v-bind:src="photo3" alt="" width="100%" height="100%">
            </slide>
          </carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters,} from 'vuex'
  import moment from "moment";
  import {
    mdiAccountSearchOutline, mdiPrinter, mdiEyeCircle, mdiPencil
    , mdiDelete
  } from '@mdi/js'
  import NewAccomplishment from '../user_interface/register/NewAccomplishment'

  export default {
    components: {
      NewAccomplishment,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
          mdiEyeCircle,
          mdiPencil,
          mdiDelete,
        },
      }
    },
    data() {
      return {
        photo1: '',
        photo2: '',
        photo3: '',

        donors_dialog: false,
        volunteers_dialog: false,
        expenses_dialog: false,
        narrative_dialog: false,
        edit_dialog: false,
        photos_dialog: false,
        is_yearly: false,

        month_of: '',
        district: '',
        total_expense: '0.00',
        district_items: [],
        list_of_new_member: [],
        month_of_items: [],
        list_of_accomplishment: [],

        list_of_donors: [],
        list_of_volunteers: [],
        selected_report: {},
        list_of_expenses: [],
        narratives: '',
        value_props: {},
        key: 0,
      }
    },
    mounted() {
      this.retrieve_transaction_month()
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'user_id', 'district_id', 'district_name', 'position']),
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('accomplishment', ['get_list_of_accomplishment', 'delete_accomplishment', 'delete_participants']),
      ...mapActions('district', ['list_of_district_have_accomplishment']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      view_photos(value) {
        this.selected_report = value
        this.photo1 = value.photo_1
        this.photo2 = value.photo_2
        this.photo3 = value.photo_3
        this.photos_dialog = true
      },
      view_donors(value) {
        this.list_of_donors = value.donors
        this.donors_dialog = true
      },
      view_participant(value) {
        this.selected_report = value
        this.list_of_volunteers = value.participants
        this.volunteers_dialog = true
      },
      view_expenses(value) {
        this.list_of_expenses = value.expenses_breakdown
        this.expenses_dialog = true
      },
      view_narrative(value) {
        this.narratives = value.narratives
        this.narrative_dialog = true
      },
      edit(value) {
        this.value_props = value
        this.edit_dialog = true
        this.key++
      },
      on_update_details() {
        this.edit_dialog = false
        this.get_list_of_accomplishment_items()
      },
      on_change_report() {
        this.district_items = []
        this.list_of_accomplishment = []
        this.month_of = ''
      },
      async get_list_of_district() {
        if (this.month_of_items.length > 0) {
          var index = this.is_yearly ? this.month_of_items.map(function (x) {
            return x.year
          }).indexOf(this.month_of) : this.month_of_items.map(function (x) {
            return x.id
          }).indexOf(this.month_of)
          if (index != -1) {
            if (this.district_id === null) {
              const data = new FormData()
              data.append('month_of', this.is_yearly ? this.month_of_items[index].year : this.month_of_items[index].month_of);
              data.append('is_yearly', this.is_yearly ? 1 : 0);
              await this.list_of_district_have_accomplishment(data)
                .then((response) => {
                  this.district_items = response.data
                  this.district_items.splice(0, 0, {
                    id: 0,
                    district_name: 'All'
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              this.district_items.push({
                'id': this.district_id,
                'district_name': this.district_name,
              })
              this.district = this.district_id
            }
            await this.get_list_of_accomplishment_items()
          }
        }
      },
      get_list_of_accomplishment_items() {
        this.list_of_accomplishment = []
        if (this.month_of_items.length > 0) {
          var index = this.is_yearly ? this.month_of_items.map(function (x) {
            return x.year
          }).indexOf(this.month_of) : this.month_of_items.map(function (x) {
            return x.id
          }).indexOf(this.month_of)
          if (index != -1) {
            const data = new FormData()
            data.append('month_of', this.is_yearly ? this.month_of_items[index].year : this.month_of_items[index].month_of);
            data.append('created_id', this.user_id);
            data.append('district_id', this.district);
            data.append('is_yearly', this.is_yearly ? 1 : 0);
            this.get_list_of_accomplishment(data)
              .then((response) => {
                this.list_of_accomplishment = response.data
                var tot = 0
                this.list_of_accomplishment.forEach(function (item) {
                  tot += parseFloat(item.expenses)
                });
                this.total_expense = this.formatPrice(tot)
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      },
      delete_volunteerss(value) {
        if (confirm("Are you sure you want to delete participants")) {
          const data = new FormData()
          data.append('id', value.id);
          this.delete_participants(data)
            .then(() => {
              this.get_list_of_accomplishment_items()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      delete_accomplishmentss(value) {
        if (confirm("Are you sure you want to delete accomplishment")) {
          const data = new FormData()
          data.append('accomplishment_id', value.id);
          this.delete_accomplishment(data)
            .then(() => {
              this.get_list_of_accomplishment_items()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)

        var month = this.month_of_items[indexMonth].month_of
        var indexDistrict = this.district_items.map(function (x) {
          return x.id
        }).indexOf(this.district)
        var districted = 'All'
        if (indexDistrict != -1) {
          districted = this.district_items[indexDistrict].district_name
        }
        array.push(
          [
            {text: 'District/Club', alignment: 'center', style: 'label'},
            {text: 'Date of Project', alignment: 'center', style: 'label'},
            {text: 'Type of Sector', alignment: 'center', style: 'label'},
            {text: 'Name of Project', alignment: 'center', style: 'label'},
            {text: 'Location', alignment: 'center', style: 'label'},
            {text: 'Recipients', alignment: 'center', style: 'label'},
          ]
        )

        if (this.list_of_accomplishment.length > 0) {
          this.list_of_accomplishment.forEach(function (item, index) {
            array.push(
              [
                {text: item.distict.district_name, alignment: 'center'},
                {text: item.date_of_project, alignment: 'left'},
                {text: item.type_of_sector, alignment: 'left'},
                {
                  text: item.name_of_the_project,
                  alignment: 'left'
                },
                {text: item.location, alignment: 'center'},
                {text: item.recipients, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. NA CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF ACCOMPLISHMENT', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {
              text: 'District/Club: ' + districted,
              style: 'main_info'
            },
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [170, 40, 40, 150, 150, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          // footer: {
          //   columns: [
          //     {
          //       text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //       alignment: 'center',
          //       style: 'tableExample'
          //     }
          //   ]
          // },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
